export const PAGE_INDEX = '/';
export const PAGE_LOGIN = '/login';
export const PAGE_REGISTER = '/register';
export const PAGE_SIGN_IN = '/signin';
export const PAGE_FORGOT_PASSWORD = '/forgot-password';
export const PAGE_RESET_PASSWORD = '/reset-password';
export const PAGE_404 = '/404';
export const PAGE_TECHNICAL_CONDITIONS = '/technical-conditions';
export const PAGE_ORDER = '/order';
export const PAGE_ORDER_PROCESS = '/order-process';
export const PAGE_USER_FLOW = '/user-flow';
export const PAGE_AUTH = '/auth';
export const PAGE_ORDER_CONFIG = '/order-config';
export const PAGE_AUTH_PAYMENT = '/auth-payment';
export const PAGE_TERM_CONDITIONS = '/term-conditions';
export const PAGE_WORK_FLOW = '/work-flow';
export const PAGE_AUTOMATIC_PROCESSING = '/automatic-processing';
export const PAGE_AUTOMATIC_PROCESSING_LIST = '/automatic-processing-list';
export const PAGE_PLAN = '/plan';
