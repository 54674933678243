import { gql } from '@apollo/client';

export const GET_EMAIL_VERIFY = gql`
  mutation getEmailVerify($type: VerificationType!, $email: String!) {
    getEmailVerify(type: $type, email: $email) {
      token
    }
  }
`;
export const VERIFY = gql`
  mutation verify($token: String!, $code: String!) {
    verify(token: $token, code: $code) {
      token
    }
  }
`;
