import React from 'react';
import PublicRoutes from 'pages/public';
import { useCallStore } from 'context/call.store';
import Dashboard from 'pages/private/dashboard';
const RoutesWrapper: React.FC = () => {
  const { authenticated } = useCallStore();

  if (authenticated === null) return null;

  const routesToRender = authenticated ? <Dashboard /> : <PublicRoutes />;

  return <>{routesToRender}</>;
};

export default RoutesWrapper;
