import RadioCard from 'components/Card/RadioCard';
import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';

type Props = {
  control: Control<any, any>;
  name: string;
  data: any[];
};

const Index = ({ control, name, data }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <div className=" w-full" ref={ref}>
            <div className="grid grid-cols-1  sm:grid-cols-6 gap-2">
              {data.map((item, index) => (
                <div className="col-span-2	 h-full" key={index}>
                  <RadioCard item={item} selectedItem={value} onSelect={(item) => onChange(item)} />
                </div>
              ))}
            </div>
            <p className="mt-2 mb-2 text-red-500 text-xs">
              {errors && (errors[name] as FieldError)?.message}
            </p>
          </div>
        );
      }}
    />
  );
};

export default Index;
