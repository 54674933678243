import { gql } from '@apollo/client';
export const GET_PARTNER_PLANS = gql`
  query getPartnerPlans($id: ID!) {
    getPartnerPlans(id: $id) {
      id
      startAt
      endAt
      price
      state
      plan {
        id
        type
        description
        state
      }
    }
  }
`;
export const GET_PARTNER_PLAN = gql`
  query getPartnerPlan($id: ID!) {
    getPartnerPlan(id: $id) {
      id
      startAt
      endAt
      price
      state
      plan {
        id
        type
        description
        state
      }
    }
  }
`;

export const GET_PLANS = gql`
  {
    getPlans {
      id
      type
      createdAt
      description
      state
      configs {
        id
        type
        name
        value
      }
      prices {
        id
        name
        description
        pricing
        duration
        unit
        price
      }
    }
  }
`;

export const GET_PLAN = gql`
  query getPlan($id: ID!) {
    getPlan(id: $id) {
      id
      type
      description
      state
      configs {
        id
        type
        name
        value
      }
      prices {
        id
        name
        description
        pricing
        duration
        unit
        price
      }
    }
  }
`;
