import { gql } from '@apollo/client';

export const ON_UPDATED_OFFER = gql`
  subscription onUpdatedOffer($partner: ID) {
    onUpdatedOffer(partner: $partner) {
      id
      event
      user
      partner
      offer {
        id
        createdAt
        partnerId
        price
        types
        state
      }
    }
  }
`;
