import * as yup from 'yup';
import { FieldValues } from 'react-hook-form';

export const registerSchema = yup.object().shape({
  email: yup.string().email('И-мэйл хаяг буруу байна').required('И-мэйл оруулна уу'),
  pin: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) >= 2
      ? yup.number().required('4 оронтой кодыг оруулна уу')
      : yup.string().notRequired();
  }),
  partnerName: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;

    return Number(step) >= 3 ? yup.string().required('Байгууллагын нэр оруулна уу') : yup.string();
  }),

  companyPhone: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;

    return Number(step) >= 3
      ? yup.string().required('Байгууллагын утас дугаар оруулна уу')
      : yup.string();
  }),
  lastName: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;

    return Number(step) >= 3 ? yup.string().required('Ажилтны овог оруулна уу') : yup.string();
  }),
  firstName: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;

    return Number(step) >= 3 ? yup.string().required('Ажилтны нэр оруулна уу') : yup.string();
  }),

  password: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) >= 3
      ? yup.string().min(6, 'Нууц үг 6 оронтой байх шаардлагатай').required('Нууц үгээ оруулна уу')
      : yup.string().notRequired();
  }),
  passwordRepeat: yup.string().when(['step', 'type'], (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    let password = options.parent.password;

    return Number(step) >= 3
      ? password && password.length > 0
        ? yup
            .string()
            .oneOf([yup.ref('password')], 'Нууц үгүүд таарахгүй байна')
            .required('Нууц үгээ давтан оруулна уу')
        : yup.string().notRequired()
      : yup.string().notRequired();
  }),
});
