import { FieldValues, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import FormTemplate from 'templates/FormTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import { userAuthPaymentSchema } from 'components/resolvers';
import { PAGE_INDEX, PAGE_REGISTER } from 'constants/pages';
import FormInputTemplate from 'templates/FormInputTemplate';
import ControlledBank from 'components/ControlledForms/ControlledBank';
import { CiWarning } from 'react-icons/ci';
import { LuCheckCircle } from 'react-icons/lu';
import ControlledWorkTypes from 'components/ControlledForms/ControlledWorkTypes';
import { useCallStore } from 'context/call.store';
import { FaPlugCircleBolt } from 'react-icons/fa6';
import { RiUserSettingsLine } from 'react-icons/ri';
import { TbPhotoBolt } from 'react-icons/tb';

const Index = () => {
  let history = useNavigate();
  const { setAuthenticated } = useCallStore();
  const { control, handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(userAuthPaymentSchema) as any,
    defaultValues: {
      step: 4,
      plans: [],
    },
  });

  const { step } = watch();

  const onSubmit = (data: FieldValues) => {
    if (data.step < 7) {
      setValue('step', data.step + 1);
    } else if (data.step === 7) {
      setAuthenticated(true);
      history(PAGE_INDEX);
    }
  };

  const goBackStep = () => {
    if (step === 5) {
      setValue('step', 4);
    } else if (step === 6) {
      setValue('step', 5);
    } else if (step === 4) {
      history(PAGE_REGISTER);
    }
  };

  const works = [
    {
      id: 0,
      name: 'Цахилгааны угсралт',
      logo: <FaPlugCircleBolt className="h-7 w-7" />,
      price: 100000,
    },
    {
      id: 1,
      name: 'Техникийн хяналт',
      logo: <RiUserSettingsLine className="h-7 w-7" />,
      price: 100000,
    },
    {
      id: 2,
      name: 'Зургийн компани',
      logo: <TbPhotoBolt className="h-7 w-7" />,
      price: 100000,
    },
  ];

  return (
    <FormTemplate
      totalSteps={7}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      onBack={() => goBackStep()}
    >
      <FormInputTemplate
        isHide={step === 4}
        formTypeName="Ажил гүйцэтгэгчийн бүртгэл"
        headerText="Багц сонгох"
        headerDescription=" Таны төлбөр төлөгдсөнөөр та  ажлын захиалга бүхий цахим биржэд оролцож ажил гүйцэтгэх боломжтой. "
      >
        <ControlledWorkTypes name="plans" data={works} control={control} />
      </FormInputTemplate>
      <FormInputTemplate
        isHide={step === 5}
        formTypeName="Ажил гүйцэтгэгчийн бүртгэл"
        headerText="Төлбөрийн  нөхцөл"
        headerDescription=" Таны төлбөр төлөгдсөнөөр та  ажлын захиалга бүхий цахим биржэд оролцож ажил гүйцэтгэх боломжтой. "
      >
        <ControlledBank control={control} name="paymentType" />
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 6}>
        <div className=" flex w-full  h-full  items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <CiWarning className="animate-pulse text-amber-400 h-20 w-20" />
            <p className="font-medium mt-10">Таны төлбөр төлөгдсөнөөр баталгаажна.</p>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 7}>
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <LuCheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-medium mt-10">Бүртгэл амжилттай</p>
            <p className="font-medium mt-10">Таны төлбөр амжилттай төлөгдлөө.</p>
          </div>
        </div>
      </FormInputTemplate>
    </FormTemplate>
  );
};

export default Index;
