import logo from '../../../assets/logo.png';
import { RxHamburgerMenu } from 'react-icons/rx';
import { dashboardRoutes } from './routes';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import { TbCalendarBolt } from 'react-icons/tb';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { MdPriceCheck } from 'react-icons/md';
import { RxCountdownTimer } from 'react-icons/rx';
import { MdOutlinePriceChange } from 'react-icons/md';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { useContext } from 'react';
import { AuthContext } from 'providers/auth';
import { useQuery } from '@apollo/client';
import { GET_OFFERS, GET_PARTNER_PLANS } from 'graphql/query';
import { useCallStore } from 'context';
import { useTranslation } from 'react-i18next';
import { IPartnerPlan } from 'types';
import { GrStatusGood } from 'react-icons/gr';
import { LoadingSpinner } from 'components';
import { LiaListAlt } from 'react-icons/lia';
import { isEmpty } from 'lodash';

const Index = () => {
  let history = useNavigate();
  let location = useLocation();
  const { t } = useTranslation('language');

  const { signOut } = useContext(AuthContext);
  const { partnerId } = useCallStore();

  const { data: getOffers, loading: offerLoading } = useQuery(GET_OFFERS);

  const { data, loading } = useQuery(GET_PARTNER_PLANS, {
    skip: !partnerId,
    variables: { id: partnerId },
  });

  const pending: any = getOffers?.getOffers.filter((offer: any) => offer.state === 'PENDING');

  const completed: any = getOffers?.getOffers.filter((offer: any) => offer.state === 'ACCEPTED');

  const navigations = [
    {
      name: 'Ажил',
      path: '/',
      logo: <TbCalendarBolt className="h-6 w-6" />,
      count: null,
    },
    {
      name: 'Үнийн санал ',
      path: '/offer',
      logo: <MdOutlinePriceChange className="h-6 w-6" />,
      count: isEmpty(pending) ? null : pending?.length,
    },
    {
      name: 'Миний ажил',
      path: '/my-work',
      logo: <LiaListAlt className="h-6 w-6" />,
      count: isEmpty(completed) ? null : completed?.length,
    },
    // {
    //   name: 'Бригад',
    //   path: '/teams',
    //   logo: <AiOutlineTeam className="h-6 w-6" />,
    // },
    // {
    //   name: 'Гэрээ',
    //   path: '/term',
    //   logo: <CgNotes className="h-6 w-6" />,
    // },
  ];

  if (loading || offerLoading) return <LoadingSpinner />;

  const navigation = (
    <nav className="menu bg-transparent p-2 rounded-md h-full justify-between ">
      <section className="menu-section h-full place-content-between">
        <ul className="menu-items ">
          {navigations.map((nav, index) => (
            <li
              key={index}
              className={`menu-item text-white hover:bg-[#6987af]  ${
                location.pathname === nav.path ? 'bg-[#6987af]' : ''
              } `}
              onClick={() => history(nav.path)}
            >
              <div className="text-white">{nav.logo}</div>
              <div className="flex justify-between w-full">
                <span>{nav.name}</span>
                {nav.count && (
                  <span className="inline-flex relative items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-red-500 rounded-full  ">
                    <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-500 opacity-75"></span>
                    <span className=" flex justify-center w-full">{nav.count}</span>
                  </span>
                )}
              </div>
            </li>
          ))}
          <li className={`menu-item text-white hover:bg-[#6987af] `} onClick={() => signOut()}>
            <RiLogoutBoxLine className="h-6 w-6 text-white" />
            Гарах
          </li>
        </ul>
        <ul className="menu-items bg-[#6987af] rounded-lg ">
          {data &&
            data?.getPartnerPlans.map((plans: IPartnerPlan) => {
              const endAtDate = new Date(plans.endAt);
              const remainingDays = Math.ceil(
                (endAtDate.getTime() - Date.now()) / (1000 * 60 * 60 * 24),
              );
              return (
                <>
                  <div className="grid p-2 pb-1 ">
                    <div className="flex gap-2">
                      <MdPriceCheck className="h-6 w-6 text-green-500" />
                      <span className="text-white font-semibold ">{t(plans.plan.type)}</span>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex mt-2 gap-1 col-span-1">
                        <GrStatusGood className="text-gray-300" />
                        <span className="text-xs text-gray-300">Төлөв:</span>
                      </div>
                      <div className="flex mt-2 gap-1 col-span-1">
                        <div className="flex w-full text-end place-content-end">
                          <span className="text-xs text-gray-300 text-end">
                            {t(plans.plan.state)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-2">
                      <div className="flex mt-2 gap-1 col-span-1">
                        <RxCountdownTimer className="text-gray-300" />
                        <span className="text-xs text-gray-300">Дуусах:</span>
                      </div>
                      <div className="flex mt-2 gap-1 col-span-1">
                        <div className="flex w-full text-end place-content-end">
                          <span className="text-xs text-gray-300 text-end">
                            {remainingDays} өдөр
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-100 m-1 mt-0 " />
                </>
              );
            })}

          <div className={`${isEmpty(data?.getPartnerPlans) ? '' : 'p-2 pt-0'}`}>
            <div
              onClick={() => history('/plan')}
              className={`${
                isEmpty(data?.getPartnerPlans) ? 'bg-[#6987af] ' : 'bg-[#164789] '
              } w-full cursor-pointer text-sm text-white p-2 rounded-lg text-center `}
            >
              Багц авах
            </div>
          </div>
        </ul>
      </section>
    </nav>
  );

  return (
    <div className="grid min-h-screen w-full grid-cols-1 lg:grid-cols-[280px_1fr]">
      <div className="hidden border-r bg-[#164789] lg:block ">
        <div className="flex h-full max-h-screen flex-col gap-2">
          <div className="flex h-[60px] items-center border-b border-[#7aa6e1] px-6">
            <div className="flex items-center gap-2 font-semibold">
              <img src={logo} alt="logo" className="h-10 w-10" />
              <span className="text-white font-bold text-base ">EPOWER</span>
            </div>
          </div>
          <div className="flex-1 overflow-auto py-2">{navigation}</div>
        </div>
      </div>
      <div className="flex flex-col ">
        <header className="flex h-22 items-center gap-4  border-b bg-gray-100/40 px-4 lg:h-[60px] lg:px-6 ">
          <input type="checkbox" id="drawer-left" className="drawer-toggle" />
          <label htmlFor="drawer-left" className="lg:hidden  p-1 rounded-lg">
            <RxHamburgerMenu className="w-6 h-6" />
          </label>
          <label className="overlay" htmlFor="drawer-left"></label>
          <div className="drawer">
            <div className="drawer-content bg-gray-800 pt-10 flex flex-col h-full">
              <label htmlFor="drawer-left" className="h-full">
                <div className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</div>
                <div className="w-full mt-4 bg-gray-800 h-full">{navigation}</div>
              </label>
            </div>
          </div>

          <div className="flex-1 ">
            <div className="flex items-center gap-2">
              <h1 className="font-semibold text-lg hidden md:flex text-[#164789]">
                {navigations.find((nav) => nav.path === location.pathname)?.logo}
              </h1>
              <h1 className="font-semibold text-lg text-[#164789]">
                {navigations.find((nav) => nav.path === location.pathname)?.name}
              </h1>
            </div>

            {location.pathname === '/' && (
              <div className=" text-[#164789]  hidden  md:flex place-items-start     ">
                <div className="flex items-center gap-2 ">
                  <span className="text-sm font-semibold">
                    Захиалга өгсөн иргэн, аж ахуйн нэгжүүдийн ажлуудтай танилцаж өөрт тохирсон ажлыг
                    гүйцэтгэхээр үнийн санал өгөх боломжтой.
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className="relative inline-flex items-center p-3 text-sm font-medium text-center text-white">
            <div className="p-1 rounded-full bg-gray-200">
              <IoIosNotificationsOutline className="w-6 h-6 text-gray-600" />
            </div>

            <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-red-500 border-2 border-white rounded-full -top-0 -end-0 ">
              20
            </div>
          </div>
        </header>
        <main className="flex-1 p-4 md:p-6 grid gap-4 md:gap-8">
          <Routes>
            {dashboardRoutes.map((route, index) => (
              <Route key={index} path={route.path} element={<route.component />} />
            ))}
          </Routes>
        </main>
      </div>
    </div>
  );
};

export default Index;
