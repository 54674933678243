import { useTranslation } from 'react-i18next';
import { formatWithCommas } from 'utils';

type Props = {
  item: any;
  selectedItem: any;
  onSelect: (item: any) => void;
};

const RadioCard = ({ item, onSelect, selectedItem }: Props) => {
  const { t } = useTranslation('language');
  const isSelected = selectedItem?.id === item.id;

  return (
    <div
      onClick={() => onSelect(item)}
      className={`rounded-lg bg-white h-full cursor-pointer border-2 ${
        isSelected ? 'border-gray-500' : 'border-gray-100'
      } p-4 shadow-sm transition-all hover:shadow-lg `}
    >
      <div className="space-y-4 grid h-full place-content-between">
        <div className="flex items-center justify-between">
          <h3 className="text-2xl font-bold ">{t(item.type)}</h3>
        </div>
        <p className="text-gray-500 text-ellipsis ">{item.description}</p>
        <div className="flex items-baseline gap-2">
          <span className="text-lg font-bold">₮{formatWithCommas(item.prices[0].price)}</span>
          <span className="text-gray-500 ">/ 1жил</span>
        </div>
      </div>
    </div>
  );
};

export default RadioCard;
