import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerSchema } from 'components/resolvers';
import { useContext, useEffect, useState } from 'react';
import FormTemplate from 'templates/FormTemplate';
import ControlledInput from 'components/ControlledForms/ControlledInput';
import ControlledFourDigits from 'components/ControlledForms/ControlledFourDigits';
import { useNavigate } from 'react-router-dom';
import FormInputTemplate from 'templates/FormInputTemplate';
import FormBoxTemplate from 'templates/FormBoxTemplate';
import { PAGE_INDEX } from 'constants/pages';
import { useMutation } from '@apollo/client';
import { GET_EMAIL_VERIFY, REGISTER_PARTNER, VERIFY } from 'graphql/mutation';
import { useNotifications } from 'providers/notification';
import { AuthContext } from 'providers/auth';

const Index = () => {
  let history = useNavigate();
  const [time, setTime] = useState(60000);
  const { addNotification } = useNotifications();
  const { authenticate } = useContext(AuthContext);

  const { control, handleSubmit, setValue, watch, setError } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(registerSchema) as any,
    defaultValues: {
      step: 1,
    },
  });

  const { step, email, pin, verifyToken, start } = watch();

  const [registerPartner, { loading: registering }] = useMutation(REGISTER_PARTNER, {
    onCompleted: (data) => {
      authenticate(data.registerPartner.token, () => {
        addNotification('Бүртгэл амжилттай!', 'success');
        history(PAGE_INDEX);
      });
    },
    onError: (error) => {
      setValue('step', 3);
      addNotification(error.message, 'error');
    },
  });

  const [getEmailVerify, { loading }] = useMutation(GET_EMAIL_VERIFY, {
    onCompleted: (data) => {
      setValue('step', 2);
      setValue('verifyToken', data.getEmailVerify.token);
      setValue('start', true);
    },
    onError: (error) => {
      setError('email', { type: 'server', message: error.message });
    },
  });

  const [verify, { loading: loadingVerify }] = useMutation(VERIFY, {
    onCompleted: (data) => {
      setValue('step', 3);
    },
    onError: (error) => {
      setValue('step', 2);
      setError('pin', { type: 'server', message: error.message });
    },
  });

  const onSubmit = (data: FieldValues) => {
    if (step === 1) {
      getEmailVerify({ variables: { type: 'R', email } });
    }
    if (step === 3) {
      let input = {
        firstName: data.firstName,
        lastName: data.lastName,
        partnerName: data.partnerName,
        password: data.password,
        token: data.verifyToken,
        phone: data.companyPhone,
      };
      registerPartner({ variables: { input: input } });
    }
  };

  useEffect(() => {
    let interval: any = null;

    if (start) {
      if (time < 1) {
        setValue('start', false);
      } else {
        interval = setInterval(() => {
          setTime((preTime) => preTime - 10);
        }, 10);
      }
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [start, time, setValue]);

  useEffect(() => {
    if (step === 2 && pin && pin.length === 4 && verifyToken) {
      verify({ variables: { token: verifyToken, code: pin } });
    }
  }, [pin, setValue, verify, verifyToken, step]);

  const goBackStep = () => {
    if (step === 1) {
      history(PAGE_INDEX);
    } else if (step === 2) {
      setValue('step', 1);
    } else if (step === 3) {
      setValue('step', 1);
    }
  };

  const onTry = () => {
    if (start) return;
    getEmailVerify({ variables: { type: 'R', email } });
    setTime(60000);
    setValue('start', true);
  };

  return (
    <FormTemplate
      totalSteps={7}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      onBack={goBackStep}
      loading={loading || loadingVerify || registering}
      disabled={loading || loadingVerify || registering}
    >
      <FormInputTemplate
        isHide={step === 1}
        headerText="Таны и мэйл хаяг"
        headerDescription="Энэ бол бүртгэлтэй компаниудад харагдах и мэйл юм. Та өөрийн хувийн мэдээлэл хэсэгт солих боломжтой."
      >
        <FormBoxTemplate>
          <ControlledInput
            control={control}
            text="И мэйл хаягаа оруулна уу"
            name="email"
            inputMode="email"
            type="text"
          />
        </FormBoxTemplate>
      </FormInputTemplate>

      <FormInputTemplate
        isHide={step === 2}
        headerText="И мэйл баталгаажуулалт"
        headerDescription="Бидний саяхан илгээсэн 4 оронтой кодыг оруулна уу"
      >
        <FormBoxTemplate>
          <div className="text-sm w-full pb-4   text-gray-800  text-start">
            <span>{email}</span>
          </div>
          <ControlledFourDigits loading={false} control={control} name="pin" />
          <div
            onClick={() => onTry()}
            className={` ${
              start ? ' text-gray-400 ' : ' inline p-2  bg-gray-300 rounded-lg text-gray-700'
            } text-sm   cursor-pointer mb-2 text-start`}
          >
            <span>Дахин авах</span>
            <span>
              {time !== 0 && (
                <span className="text-gray1">
                  ({('' + Math.floor((time / 1000) % 60)).slice(-2)})
                </span>
              )}{' '}
            </span>
          </div>
        </FormBoxTemplate>
      </FormInputTemplate>

      <FormInputTemplate isHide={step === 3}>
        <div className="w-full">
          <span className="text-gray-800 font-semibold">Байгууллагын мэдээлэл</span>
        </div>
        <FormBoxTemplate>
          <ControlledInput
            control={control}
            text="Байгууллагын нэр"
            name="partnerName"
            type="text"
          />
          <ControlledInput
            control={control}
            text="Байгууллагын утас"
            name="companyPhone"
            inputMode="numeric"
            type="text"
          />
        </FormBoxTemplate>
        <div className="w-full mt-3">
          <span className="text-gray-800 font-semibold">Ажилтны мэдээлэл</span>
        </div>
        <FormBoxTemplate>
          <ControlledInput control={control} text="Овог" name="lastName" type="text" />
          <ControlledInput control={control} text="Нэр" name="firstName" type="text" />
          <ControlledInput
            control={control}
            text="Нууц үг оруулна уу"
            name="password"
            inputMode="text"
            type="password"
          />
          <ControlledInput
            control={control}
            text="Нууц үг давтан оруулна уу"
            name="passwordRepeat"
            inputMode="text"
            type="password"
          />
        </FormBoxTemplate>
      </FormInputTemplate>
    </FormTemplate>
  );
};

export default Index;
