import { useMutation, useQuery } from '@apollo/client';
import { GET_PARTNER_PLANS, GET_TASKS } from 'graphql/query';
import { publicationTypes, purposeWorks, workTypes } from 'mocks';
import DataTable, { TableStyles } from 'react-data-table-component';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { downloadFile, getPurposeWork } from 'tools';
import moment from 'moment';
import { AiOutlineInbox } from 'react-icons/ai';
import { IDocument, IPartnerPlan, ITask } from 'types';
import { FiDownload } from 'react-icons/fi';
import { useRef, useState } from 'react';
import Button from 'components/Button';
import { CiWarning } from 'react-icons/ci';
import { useCallStore } from 'context';
import { PAGE_PLAN } from 'constants/pages';
import { useNavigate } from 'react-router-dom';
import { FieldValues, useForm } from 'react-hook-form';
import { createPlanSchema } from 'components/resolvers';
import { yupResolver } from '@hookform/resolvers/yup';
import ControlledInputMask from 'components/ControlledForms/ControlledInputMask';
import { CREATE_OFFER } from 'graphql/mutation';
import { useNotifications } from 'providers/notification';
import { isEmpty } from 'lodash';

const Order = () => {
  let history = useNavigate();
  const drawerRef = useRef<HTMLInputElement>(null);
  const modalRef = useRef<HTMLInputElement>(null);
  const { partnerId } = useCallStore();
  const { data, loading, refetch } = useQuery(GET_TASKS);
  const { addNotification } = useNotifications();
  const [selectedRow, setSelectedRow] = useState<any>();
  const [selectedType, setType] = useState<string>(publicationTypes[0].value);

  const [createOffer, { loading: creating }] = useMutation(CREATE_OFFER, {
    onCompleted: async (data) => {
      if (drawerRef.current) {
        drawerRef.current.checked = false;
      }
      if (modalRef.current) {
        modalRef.current.checked = false;
      }
      addNotification('Үнийн санал амжилттай илгээгдлээ', 'success');
      refetch();
    },
  });

  const { data: partnerPLan, loading: partnerLoading } = useQuery(GET_PARTNER_PLANS, {
    skip: !partnerId,
    variables: { id: partnerId },
  });

  const { control, handleSubmit } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(createPlanSchema) as any,
  });

  if (loading || partnerLoading)
    return (
      <div className="flex w-full h-full items-center justify-center">
        <AiOutlineLoading3Quarters className="animate-spin h-10 w-10 mr-2 " />
      </div>
    );

  const customStyles: TableStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          height: '60px',
        },
      },
    },
  };

  const columns: any = [
    {
      name: 'Ажлын зориулалт',
      selector: (row: any) => {
        const config = row.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'purposeWork:') : null;

        if (!purposeWork) return '';

        const purposeWorkValue = purposeWork.split(':')[1];

        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {purposeWorks.find((work: { name: string; value: string }) =>
                work?.value?.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      width: '200px',
      wrap: true,
      sortable: true,
      center: false,
    },
    {
      name: 'Ажлын Төрөл',
      selector: (row: any) => {
        const config = row.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'workType:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {workTypes.find((work: { name: string; value: string }) =>
                work?.value?.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      sortable: true,
      width: '200px',
      center: true,
    },
    {
      name: 'Чадал',
      selector: (row: any) => {
        const config = row.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'ability:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return purposeWorkValue || '';
      },
      wrap: true,
      sortable: true,
      width: '100px',
      center: true,
    },
    {
      name: 'Нийтэлсэн огноо',
      selector: (row: any) => (
        <span className="font-normal">{moment(row.completedAt).format('YYYY-MM-DD')}</span>
      ),
      width: '150px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: <span className="text-center">Ажлын тоо хэмжээ</span>,
      selector: (row: any) => {
        const adminDocument = row?.documents?.find((doc: IDocument) => doc.input === 'admin');

        const isAdminDocumentPresent = adminDocument !== undefined;

        return (
          <span
            style={{ textWrap: 'balance' }}
            className="flex gap-2 text-wrap cursor-pointer font-normal place-content-center"
          >
            {isAdminDocumentPresent ? (
              <span
                onClick={() => downloadFile(adminDocument.path)}
                className="text-xs text-gray-800"
              >
                Татах
              </span>
            ) : (
              <span className="text-xs text-gray-800">Хүлээгдэж байгаа</span>
            )}

            {isAdminDocumentPresent && (
              <FiDownload
                onClick={() => downloadFile(adminDocument.path)}
                className="text-gray-600 text-lg"
              />
            )}
          </span>
        );
      },
      width: '120px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: <span className="font-bold text-sm">Оролцох</span>,
      selector: (row: any) => (
        <>
          {!isEmpty(
            row.offers?.filter(
              (item: any) => item?.partnerId === partnerId && item.types?.includes(selectedType),
            ),
          ) ? (
            <div className="p-2 text-center cursor-pointer bg-green-200  rounded-lg">
              <span className=" text-center text-green-600 ">Үнийн санал илгээсэн</span>
            </div>
          ) : (
            <label htmlFor={`${window.innerWidth < 1024 ? 'drawer-bottom' : 'modal-1'}`}>
              <div
                className="p-2  cursor-pointer bg-gray-300  rounded-lg"
                onClick={() => onSelect(row)}
              >
                <span className="  text-gray-600 ">Үнийн санал өгөх</span>
              </div>
            </label>
          )}
        </>
      ),
      wrap: true,
      width: '160px',
      sortable: true,
      center: true,
    },
  ];

  const onSelect = (row: any) => {
    setSelectedRow(row);
  };

  const onToggle = (value: string) => {
    setType(value);
  };

  const onDraw = (e: any) => {
    if (!e.target.checked) {
      setSelectedRow(null);
    }
  };

  const onModal = (e: any) => {
    if (!e.target.checked) {
      setSelectedRow(null);
    }
  };

  const emptyPlan = () => {
    return (
      <div className=" flex w-full  h-full  items-center text-center place-content-center">
        <div className="grid place-items-center ">
          <CiWarning className=" flex  animate-pulse text-amber-400 h-20 w-20" />
          <p className="font-medium mt-10 text-gray-700">Та багц идэвхжүүлнэ үү.</p>
        </div>
      </div>
    );
  };

  const offerRender = () => {
    return (
      <div className="pt-4">
        {/* <div className="p-4 mb-4 text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50 dark:text-blue-800 dark:border-blue-300  dark:bg-blue-50 ">
          <div className="flex  gap-2 ">
            <div className=" h-5 text-center ">
              <svg
                className=" h-[19px] "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
              </svg>
            </div>
            <span className="text-sm font-medium text-blue-800 dark:text-blue-800">
              Та үнийн санал өгөхдөө үйлчилгээний шимтгэл 2%-иа тооцно уу
            </span>
          </div>
        </div> */}
        <ControlledInputMask
          control={control}
          name="price"
          placeholder="Үнийн санал (төгрөгөөр)"
          type="text"
        />
      </div>
    );
  };

  const onSubmit = (data: FieldValues) => {
    createOffer({
      variables: { id: selectedRow.id, price: Number(data.price), types: [selectedType] },
    });
  };

  const goPlan = () => {
    history(PAGE_PLAN);
  };

  const tasks = data?.getTasks.filter((t: ITask) => t.workTypes?.includes(selectedType));

  const plans = partnerPLan?.getPartnerPlans.map((partner: IPartnerPlan) => partner.plan.type);

  return (
    <div className="block overflow-hidden">
      <div className="p-4 mb-4  flex   md:hidden text-blue-800 border place-items-start  border-blue-300 rounded-lg bg-blue-50  ">
        <div className="flex  gap-2 ">
          <div className=" h-5 text-center ">
            <svg
              className=" h-[19px] "
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
            </svg>
          </div>

          <span className="text-sm font-medium text-[#164789]">
            Захиалга өгсөн иргэн, аж ахуйн нэгжүүдийн ажлуудтай танилцаж өөрт тохирсон ажлыг
            гүйцэтгэхээр үнийн санал өгөх боломжтой.
          </span>
        </div>
      </div>

      <div className="flex w-full gap-2  mb-4 overflow-auto ">
        <div className="tabs  contents">
          {publicationTypes.map((type, index: number) => (
            <div
              key={index}
              onClick={() => onToggle(type.value)}
              className={`tab tab-bordered border rounded-t-lg place-content-center    px-6 ${
                type.value === selectedType
                  ? ' bg-[#164789] border border-[#164789] rounded-t-lg  text-white font-semibold tab-active'
                  : ''
              }`}
            >
              <span className="text-center">
                {type.label} (
                {data?.getTasks.filter((t: ITask) => t?.workTypes?.includes(type.value)).length})
              </span>
            </div>
          ))}
        </div>
      </div>

      <DataTable
        columns={columns}
        data={tasks ?? []}
        customStyles={customStyles}
        highlightOnHover
        noDataComponent={
          <div className="grid place-items-center	">
            <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
            <span className="text-gray-500 text-sm ">Хоосон байна</span>
          </div>
        }
        responsive
        pagination
        progressComponent={
          <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
        }
      />

      {selectedRow && (
        <>
          <input
            ref={drawerRef}
            type="checkbox"
            onChange={onDraw}
            id="drawer-bottom"
            className="drawer-toggle"
          />

          <label className="overlay" htmlFor="drawer-bottom"></label>
          <div className="drawer drawer-bottom h-[50%]  ">
            <div className="drawer-content p-0 bg-white dark:bg-white">
              <div className="p-4">
                <div className="flex place-items-center place-content-between">
                  <p className="text-gray-800 font-semibold ">Үнийн санал</p>
                  <label htmlFor="drawer-bottom" className="btn btn-sm btn-circle btn-ghost  ">
                    ✕
                  </label>
                </div>
              </div>
              <div className="py-4 px-4">
                {!plans?.includes(selectedType) ? emptyPlan() : offerRender()}
              </div>
              <div className="fixed  bottom-0 w-full z-30 clearNav bg-white dark:bg-white">
                <div className="  w-full p-4 ">
                  {!plans?.includes(selectedType) ? (
                    <Button text="Үргэлжлүүлэх" onClick={goPlan} />
                  ) : (
                    <Button text="Илгээх" loading={creating} onClick={handleSubmit(onSubmit)} />
                  )}
                </div>
              </div>
            </div>
          </div>
          <input
            ref={modalRef}
            onChange={onModal}
            className="modal-state"
            id="modal-1"
            type="checkbox"
          />
          <div className="modal">
            <label className="modal-overlay" htmlFor="modal-1"></label>

            <div className="modal-content flex flex-col  w-5/12 p-8 pt-2 ">
              <div className="flex place-items-center place-content-between">
                <p className="text-gray-800 font-semibold mt-2 ">Үнийн санал</p>
                <label
                  htmlFor="modal-1"
                  className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
                >
                  ✕
                </label>
              </div>
              <div className="pb-4">
                {!plans?.includes(selectedType) ? emptyPlan() : offerRender()}
              </div>
              <div className="  w-full">
                {!plans?.includes(selectedType) ? (
                  <Button text="Үргэлжлүүлэх" onClick={goPlan} />
                ) : (
                  <Button text="Илгээх" loading={creating} onClick={handleSubmit(onSubmit)} />
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Order;
