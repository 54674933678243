import { gql } from '@apollo/client';

export const ON_PAID_TRANSACTION = gql`
  subscription onPaidTransaction($user: ID!) {
    onPaidTransaction(user: $user) {
      id
      event
      user
      transaction {
        id
        token
        invoice
        image
        description
        entry
        amount
        state
        purpose
        object
        links {
          name
          logo
          description
          link
        }
      }
    }
  }
`;
