import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

export const userAuthPaymentSchema = yup.object().shape({
  plan: yup.object().when('step', (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) === 1
      ? yup.object().required('Багцын төрлөө сонгоно уу.')
      : yup.object().notRequired();
  }),
});

export const planPermittedSchema = (type: string, step: number) =>
  yup.object().shape({
    experience: yup.string().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 2
        ? yup.string().required('Ажлын туршлага сонгоно уу.')
        : yup.string().notRequired();
    }),
    certificateType: yup.string().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 2
        ? type === 'technicalControl'
          ? yup.string().required('Гэрчилгээний төрлөө сонгоно уу.')
          : yup.string().notRequired()
        : yup.string().notRequired();
    }),
    specialPermit: yup.string().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 2
        ? type === 'technicalControl'
          ? yup.string().notRequired()
          : yup.string().required('Тусгай зөвшөөрөлын төрлөө сонгоно уу.')
        : yup.string().notRequired();
    }),
    files: yup.array().when(['step', 'type'], (builder: FieldValues, schema, options) => {
      return Number(step) === 2
        ? type === 'technicalControl'
          ? yup.array().notRequired()
          : yup
              .array()
              .min(1, 'Дор хаяж нэг файл шаардлагатай')
              .of(
                yup.object().shape({
                  name: yup.string().required('File name is required'),
                }),
              )
        : yup.array().notRequired();
    }),
    files001: yup.array().when(['step', 'type'], (builder: FieldValues, schema, options) => {
      let certificateType = options.parent.certificateType;

      return Number(step) === 2
        ? type === 'technicalControl'
          ? certificateType === '001'
            ? yup
                .array()
                .min(1, 'Дор хаяж нэг файл шаардлагатай')
                .of(
                  yup.object().shape({
                    name: yup.string().required('File name is required'),
                  }),
                )
            : yup.array().notRequired()
          : yup.array().notRequired()
        : yup.array().notRequired();
    }),

    files002: yup.array().when(['step', 'type'], (builder: FieldValues, schema, options) => {
      let certificateType = options.parent.certificateType;

      return Number(step) === 2
        ? type === 'technicalControl'
          ? certificateType === '002'
            ? yup
                .array()
                .min(1, 'Дор хаяж нэг файл шаардлагатай')
                .of(
                  yup.object().shape({
                    name: yup.string().required('File name is required'),
                  }),
                )
            : yup.array().notRequired()
          : yup.array().notRequired()
        : yup.array().notRequired();
    }),

    paymentType: yup.string().when('step', (builder: FieldValues, schema, options) => {
      return Number(step) === 3
        ? yup.string().required('Төлбөрийн нөхцөл сонгоно уу.')
        : yup.string().notRequired();
    }),
  });
