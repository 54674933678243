import { useCallStore } from 'context/call.store';
import React, { createContext, useCallback, useEffect, useMemo } from 'react';
import decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

interface AuthContextType {
  authenticated: boolean | null;
  authenticate: (token: string, cb: Function) => void;
  signOut: () => void;
}

interface Payload {
  sub: string;
  iss: string;
  exp: number;
}

const getAccessToken = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('token');
  }
};

export const isValidToken = () => {
  const token = getAccessToken();
  if (!token) return false;
  try {
    const { exp }: Payload = decode(token);
    if (exp * 1000 < new Date().getTime()) return false;
    return true;
  } catch (e) {
    return false;
  }
};

export const getToken = () => {
  if (typeof window !== 'undefined') {
    if (isValidToken()) return getAccessToken();
  }
  return 'default-token';
};

export const getPayload = (): Payload | null => {
  const token = getAccessToken();
  if (!token) return null;
  try {
    const payload: Payload = decode(token);
    return payload;
  } catch (e) {
    return null;
  }
};

export const AuthContext = createContext({} as AuthContextType);

export const AuthProvider = ({ children }: any) => {
  let history = useNavigate();
  const { setAuthenticated, authenticated } = useCallStore();

  const authenticate = useCallback(
    (token: string, cb: Function) => {
      localStorage.setItem('token', token);
      setAuthenticated(true);
      if (cb) setTimeout(cb, 100);
    },
    [setAuthenticated],
  );

  useEffect(() => {
    setAuthenticated(isValidToken());
  }, [setAuthenticated]);

  const signOut = useCallback(() => {
    localStorage.setItem('token', '');
    history('/');
    setAuthenticated(false);
  }, [setAuthenticated]);

  const defaultContext = useMemo<AuthContextType>(() => {
    return { authenticated, authenticate, signOut };
  }, [authenticated, authenticate, signOut]);

  return <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>;
};
