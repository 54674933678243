import { useMutation, useQuery, useSubscription } from '@apollo/client';
import Button from 'components/Button';
import ControlledBank from 'components/ControlledForms/ControlledBank';
import { useCallStore } from 'context/call.store';
import { PAY_CHARGE, VALIDATE_TRANSACTION } from 'graphql/mutation';
import { GET_OFFER } from 'graphql/query';
import { ON_PAID_TRANSACTION } from 'graphql/subscription/transaction';
import { useNotifications } from 'providers/notification';
import { useState } from 'react';
import { FieldValues, useForm } from 'react-hook-form';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { CiWarning } from 'react-icons/ci';
import { LuCheckCircle } from 'react-icons/lu';
import { useNavigate, useParams } from 'react-router-dom';
import DashboardForm from 'templates/DashboardForm';
import FormInputTemplate from 'templates/FormInputTemplate';
import { ConvertImgUrl } from 'tools';
import { formatWithCommas } from 'utils';

const Index = () => {
  let history = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { addNotification } = useNotifications();
  const [transaction, setTransaction] = useState<any>();
  const { partnerId } = useCallStore();

  const { control, handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    defaultValues: {
      step: 1,
    },
  });

  const { data, loading } = useQuery(GET_OFFER, {
    skip: !id,
    variables: { id },
  });

  useSubscription(ON_PAID_TRANSACTION, {
    variables: { user: partnerId },
    skip: !partnerId,
    onData: ({ client, data }) => {
      if (!data) return;

      const { transaction: subscriptionTransaction } = data.data.onPaidTransaction;
      addNotification('Төлбөр амжилттай төлөгдлөө.', 'success');

      if (subscriptionTransaction.id === transaction.id) {
        setValue('step', 3);
      }
    },
    onError: (error) => {
      console.error('Subscription error:', error);
    },
  });

  const { step, paymentType } = watch();

  const [payCharge, { loading: paying }] = useMutation(PAY_CHARGE, {
    onCompleted: async (data) => {
      let link = null;
      if (data.payCharge.links) {
        link = data.payCharge.links.find(
          (link: any) => link.name.toUpperCase() === paymentType.toUpperCase(),
        )?.link;
      }

      if (link) {
        window.location.href = link;
      }
      setTransaction(data.payCharge);
      setValue('step', 2);
    },
    onError: (error) => {
      addNotification('Амжилтгүй', 'error');
    },
  });

  const [validateTransaction, { loading: validating }] = useMutation(VALIDATE_TRANSACTION, {
    onCompleted: async (data) => {
      if (data.validateTransaction) {
        setValue('step', 3);
      } else {
        addNotification('Төлбөр төлөгдсөнгүй', 'error');
      }
    },
    onError: (error) => {
      addNotification('Амжилтгүй', 'error');
    },
  });

  const onSubmit = (data: FieldValues) => {
    if (step === 1) {
      payCharge({ variables: { id } });
    }
  };

  const validate = () => {
    validateTransaction({ variables: { id: transaction.id } });
  };

  if (loading)
    return (
      <div className="flex w-full h-full items-center justify-center">
        <AiOutlineLoading3Quarters className="animate-spin h-10 w-10 mr-2 " />
      </div>
    );

  const renderFooter = (step: number) => {
    switch (step) {
      case 2:
        return (
          <div className="flex place-content-center w-full mt-4 border-t md:border-0 border-gray-300 p-4 md:p-0  ">
            <div className="w-full sm:w-40 text-center ">
              {transaction?.state === 'PAID' ? (
                <Button loading={paying} text="Үргэлжлүүлэх" onClick={() => history('/offer')} />
              ) : (
                <Button loading={validating} text="Төлбөр шалгах" onClick={validate} />
              )}
            </div>
          </div>
        );
      case 3:
        return (
          <div className="flex place-content-center w-full mt-4 border-t md:border-0 border-gray-300 p-4 md:p-0  ">
            <div className="w-full sm:w-40 text-center ">
              <Button text="Үргэлжлүүлэх" onClick={() => history('/my-work')} />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  const goBackStep = () => {
    if (step === 1) {
      history('/offer');
    }
    if (step === 2) {
      setValue('step', 1);
    }
  };

  return (
    <DashboardForm
      onBackHide={step === 3}
      totalSteps={3}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      loading={loading || paying}
      renderFooter={renderFooter(step)}
      onBack={() => goBackStep()}
    >
      <FormInputTemplate isHide={step === 1} headerText="Банк сонгох">
        <div className="flex px-6 flex-row max-w-[36rem]	 mx-auto items-center justify-between md:px-6 lg:px-8">
          <div className="w-full flex  justify-center h-full mt-10 ">
            <div className=" grid w-full">
              <div className="w-full p-2 rounded-lg text-center bg-white border border-gray-500">
                <span className="text-lg font-medium text-gray-800">
                  ₮{data && formatWithCommas(data?.getOffer.chargeAmount ?? 0)}
                </span>
              </div>
              <ControlledBank control={control} name="paymentType" />
            </div>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 2}>
        <div className=" flex w-full justify-center h-full  items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <CiWarning className=" flex lg:hidden animate-pulse text-amber-400 h-20 w-20" />
            {transaction && (
              <img src={ConvertImgUrl(transaction)} alt="pay" className="hidden lg:flex" />
            )}
            <p className="font-medium mt-10 text-gray-700">Таны төлбөр төлөгдсөнөөр баталгаажна.</p>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 3}>
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <LuCheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-medium mt-10 text-gray-500">Таны шимтгэл амжилттай төлөгдлөө</p>
          </div>
        </div>
      </FormInputTemplate>
    </DashboardForm>
  );
};

export default Index;
