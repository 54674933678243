import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().email('И-мэйл хаяг буруу байна').required('И-мэйл оруулна уу'),

  password: yup
    .string()
    .min(6, 'Нууц үг 6 оронтой байх шаардлагатай')
    .required('Нууц үгээ оруулна уу'),
});
