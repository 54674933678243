import React from 'react';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

type Props = {
  onClick: () => void;
  text: string;
  isSolid?: boolean;
  isBordered?: boolean;
  isShadow?: boolean;
  disabled?: boolean;
  isDashed?: boolean;
  loading?: boolean;
  suffix?: any;
};

const Button = ({
  onClick,
  text,
  isSolid = false,
  isShadow = false,
  isDashed = false,
  disabled = false,
  isBordered = false,
  loading = false,
  suffix,
}: Props) => {
  const solidClasses = isSolid
    ? `bg-white  ${isBordered ? ' hover:bg-gray-100' : 'border border-[#164789] text-[#164789]'} `
    : 'bg-[#164789] text-white';

  const shadowClasses = isShadow ? 'drop-shadow-xl' : '';

  return (
    <span
      className={` flex cursor-pointer text-sm   text-center ${
        isDashed ? 'border-dashed' : ''
      } 	 place-items-center w-full place-content-center py-2    rounded-lg px-3  ${
        disabled || loading
          ? 'border-[#164789] bg-gray-200 text-gray-400  '
          : `${solidClasses} ${shadowClasses}`
      }  `}
      onClick={disabled || loading ? undefined : onClick}
    >
      {loading ? (
        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-2 " />
      ) : (
        <>{suffix && <div className="mr-2">{suffix}</div>}</>
      )}
      {text}
    </span>
  );
};

export default Button;
