import ControlledWorkTypes from 'components/ControlledForms/ControlledWorkTypes';
import { userAuthPaymentSchema } from 'components/resolvers';
import { FieldValues, useForm } from 'react-hook-form';
import FormInputTemplate from 'templates/FormInputTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { GET_PARTNER_PLANS, GET_PLANS } from 'graphql/query';
import DashboardForm from 'templates/DashboardForm';
import { useCallStore } from 'context';
import { IPartnerPlan, IPlan } from 'types';
import { LoadingSpinner } from 'components';

const Plan = () => {
  let history = useNavigate();
  const { setStep, partnerId } = useCallStore();

  const { data: plansData, loading } = useQuery(GET_PLANS);

  const { data: partnerPlansData, loading: planning } = useQuery(GET_PARTNER_PLANS, {
    skip: !partnerId,
    variables: { id: partnerId },
  });

  const { control, handleSubmit, watch, setValue } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(userAuthPaymentSchema) as any,
    defaultValues: {
      step: 1,
    },
  });

  const { step } = watch();

  const partnerPlanIds =
    partnerPlansData?.getPartnerPlans?.map((partnerPlan: IPartnerPlan) => partnerPlan?.plan.id) ??
    [];

  const filteredPlans =
    plansData?.getPlans
      ?.filter((plan: IPlan) => !partnerPlanIds?.includes(plan.id))
      .sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1)) ?? [];

  if (loading || planning) return <LoadingSpinner />;

  const onSubmit = (data: FieldValues) => {
    setStep(2);
    history(`/plan/permit/${data.plan.id}`);
  };

  const goBackStep = () => {
    if (step === 2) {
      setValue('step', 1);
    }
  };

  return (
    <DashboardForm
      onBackHide={step === 1}
      totalSteps={7}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      onBack={() => goBackStep()}
    >
      <FormInputTemplate
        isHide={step === 1}
        headerText="Багц сонгох"
        headerDescription="Багцын төрөл сонгосноор та  ажлын захиалга бүхий цахим биржэд оролцож ажил гүйцэтгэх боломжтой. "
      >
        <ControlledWorkTypes name="plan" data={filteredPlans} control={control} />
      </FormInputTemplate>
    </DashboardForm>
  );
};

export default Plan;
