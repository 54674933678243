import { QPAY_BANK_TYPE } from 'constants/bank';
import khaan from './img/khanbank.png';
import khasBank from './img/xacbank.png';
import bogdBank from './img/bogdbank.png';
import capitronBank from './img/capitronbank.png';
import ckBank from './img/ckbank.png';
import most from './img/most.png';
import taniBank from './img/nibank.png';
import stateBank from './img/statebank.png';
import tbd from './img/tdbbank.png';
import ardapp from './img/ardapp.png';
import arig from './img/arig.png';
import mbank from './img/mbank.png';
import transabnk from './img/transbank.png';
import monpay from './img/monpay.png';
import socialPay from './img/socialpay.png';
import { ITransaction } from 'types';

export const ConvertQpayBankImg = (type: any) => {
  switch (type) {
    case QPAY_BANK_TYPE.MBANK:
      return mbank;
    case QPAY_BANK_TYPE.KHAAN_BANK:
      return khaan;
    case QPAY_BANK_TYPE.KHAS_BANK:
      return khasBank;
    case QPAY_BANK_TYPE.BOGD_BANK:
      return bogdBank;
    case QPAY_BANK_TYPE.CAPITRON_BANK:
      return capitronBank;
    case QPAY_BANK_TYPE.CHINGIG_KHAAN_BANK:
      return ckBank;
    case QPAY_BANK_TYPE.MOST_MONEY:
      return most;
    case QPAY_BANK_TYPE.NATIONAL_INVESTMENT_BANK:
      return taniBank;
    case QPAY_BANK_TYPE.STATE_BANK:
      return stateBank;
    case QPAY_BANK_TYPE.TRADE_AND_DEVELOPMENT_BANK:
      return tbd;
    case QPAY_BANK_TYPE.ARD_APP:
      return ardapp;
    case QPAY_BANK_TYPE.SOCIAL_PAY:
      return socialPay;
    case QPAY_BANK_TYPE.MONPAY:
      return monpay;
    case QPAY_BANK_TYPE.ARIG_BANK:
      return arig;
    case QPAY_BANK_TYPE.TRANS_BANK:
      return transabnk;
    default:
      return type;
  }
};

export const ConvertImgUrl = (transaction: ITransaction) => {
  return `data:image/jpeg;base64,${transaction.image} `;
};
