import { FieldValues } from 'react-hook-form';
import * as yup from 'yup';

export const technicalConditionSchema = yup.object().shape({
  files: yup.array().when('step', (builder: FieldValues, schema, options) => {
    let step = options.parent.step;

    return Number(step) >= 2
      ? yup
          .array()
          .min(1, 'Дор хаяж нэг файл шаардлагатай')
          .of(
            yup.object().shape({
              name: yup.string().required('File name is required'),
            }),
          )
      : yup.array().notRequired();
  }),

  paymentType: yup.string().when('step', (builder: FieldValues, schema, options) => {
    let step = options.parent.step;
    return Number(step) >= 3
      ? yup.string().required('Төлбөрийн нөхцөл сонгоно уу.')
      : yup.string().notRequired();
  }),
});
