import Order from './order';
import Plan from './plan';
import Permit from './plan/permit';
import Settings from './settings';
import Teams from './teams';
import Term from './term';
import Work from './work';
import Charge from './work/charge';
import MyWork from './myWork';

export const dashboardRoutes = [
  {
    path: '/',
    exact: false,
    component: Order,
  },
  {
    path: '/settings',
    exact: false,
    component: Settings,
  },
  {
    path: '/plan',
    exact: false,
    component: Plan,
  },
  {
    path: '/offer',
    exact: false,
    component: Work,
  },
  {
    path: '/teams',
    exact: false,
    component: Teams,
  },
  {
    path: '/term',
    exact: false,
    component: Term,
  },
  {
    path: '/my-work',
    exact: false,
    component: MyWork,
  },
  {
    path: '/plan/permit/:id',
    exact: false,
    component: Permit,
  },
  {
    path: '/offer/charge/:id',
    exact: false,
    component: Charge,
  },
  {
    path: '*',
    component: Order,
  },
];
