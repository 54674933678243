import React from 'react';
import './App.css';
import Routes from './routes';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { LANG_RESOURCES } from 'config/lang';
import { ApolloProvider } from '@apollo/client';
import client from 'providers/client';
import { AuthProvider } from 'providers/auth';
import { NotificationProvider } from 'providers/notification';
import SubscriptionProvider from 'providers/subscription';

function App() {
  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'mn',
    resources: LANG_RESOURCES,
  });

  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <NotificationProvider>
          <I18nextProvider i18n={i18next}>
            <SubscriptionProvider>
              <Routes />
            </SubscriptionProvider>
          </I18nextProvider>
        </NotificationProvider>
      </AuthProvider>
    </ApolloProvider>
  );
}

export default App;
