import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import Button from 'components/Button';
import { COMPLETE_OFFER } from 'graphql/mutation';
import { GET_ORDERS, PARTNER_WORKS } from 'graphql/query';
import { purposeWorks, workTypes } from 'mocks';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { AiOutlineInbox, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { FiDownload } from 'react-icons/fi';
import { downloadFile, getPurposeWork, orderStatusColor } from 'tools';
import { IDocument } from 'types';
import { CiWarning } from 'react-icons/ci';
import { useTranslation } from 'react-i18next';

const customStyles: TableStyles = {
  header: {
    style: {
      minHeight: '56px',
    },
  },
  cells: {
    style: {
      '&:not(:last-of-type)': {
        height: '60px',
      },
    },
  },
};

const Index = () => {
  const modalRef = useRef<HTMLInputElement>(null);
  const { t } = useTranslation('language');
  const [orders, setOrders] = useState<any>([]);
  const [selectedRow, setSelectedRow] = useState<any>();
  const [partnerWorks, { data, loading }] = useLazyQuery(PARTNER_WORKS);

  const [getOrders] = useLazyQuery(GET_ORDERS, {
    onCompleted: (data) => {
      setOrders(data?.getOrders);
      partnerWorks();
    },
  });

  const [completeOffer, { loading: completing }] = useMutation(COMPLETE_OFFER, {
    onCompleted: (data) => {
      getOrders();
      onCloseModal();
    },
  });

  const onCompleted = (id: string) => {
    completeOffer({ variables: { id: id } });
  };

  useEffect(() => {
    getOrders();
  }, []);

  const columns: any = [
    {
      name: 'Үүссэн огноо',
      selector: (row: any) => moment(row.createdAt).format('YYYY-MM-DD'),
      wrap: true,
      sortable: true,
      center: false,
      width: '140px',
    },
    {
      name: 'Захиалагчийн нэр',
      selector: (row: any) => {
        let targetOrder = orders.find((order: any) => order.id === row.task.orderId);
        return targetOrder?.user.firstName;
      },
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Утасны дугаар',
      selector: (row: any) => {
        let targetOrder = orders.find((order: any) => order.id === row.task.orderId);

        return targetOrder?.user.phone;
      },
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын Төрөл',
      selector: (row: any) => {
        const config = row.task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'workType:') : null;
        if (!purposeWork) return '';
        const purposeWorkValue = purposeWork.split(':')[1];
        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {workTypes.find((work: { name: string; value: string }) =>
                work?.value?.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      width: '150px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын зориулалт',
      selector: (row: any) => {
        const config = row.task.taskConfig;
        const purposeWork = config ? getPurposeWork(config, 'purposeWork:') : null;

        if (!purposeWork) return '';

        const purposeWorkValue = purposeWork.split(':')[1];

        return (
          <div className="block w-full my-2">
            <span className="line-clamp-3">
              {purposeWorks.find((work: { name: string; value: string }) =>
                work.value.includes(purposeWorkValue),
              )?.name || ''}
            </span>
          </div>
        );
      },
      wrap: true,
      maxWidth: '600px',
      sortable: true,
      center: true,
    },
    {
      name: 'Ажлын тоо хэмжээ',
      selector: (row: any) => {
        const adminDocument = row?.task.documents?.find((doc: IDocument) => doc.input === 'admin');

        const isAdminDocumentPresent = adminDocument !== undefined;

        return (
          <span
            style={{ textWrap: 'balance' }}
            className="flex gap-2 text-wrap cursor-pointer font-normal place-content-center"
          >
            {isAdminDocumentPresent ? (
              <span
                onClick={() => downloadFile(adminDocument.path)}
                className="text-xs text-gray-800"
              >
                Татах
              </span>
            ) : (
              <span className="text-xs text-gray-800">Хүлээгдэж байгаа</span>
            )}

            {isAdminDocumentPresent && (
              <FiDownload
                onClick={() => downloadFile(adminDocument.path)}
                className="text-gray-600 text-lg"
              />
            )}
          </span>
        );
      },
      maxWidth: '195px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: '',
      selector: (row: any) => {
        return (
          <>
            {row.state === 'COMPLETED' ? (
              <>
                <span
                  className={`badge text-wrap cursor-pointer font-normal		 ${orderStatusColor(
                    row.state,
                  )} place-content-center`}
                >
                  {t(`work.${row.state}`)}
                </span>
              </>
            ) : (
              <label htmlFor={`modal-1`}>
                <div
                  className="p-2  cursor-pointer bg-gray-300 text-center rounded-lg"
                  onClick={() => onSelect(row)}
                >
                  <span className="  text-gray-600 ">Ажил дуусгах</span>
                </div>
              </label>
            )}
          </>
        );
      },
      maxWidth: '140px',
      wrap: true,
      sortable: true,
      center: true,
    },
  ];

  const onSelect = (row: any) => {
    setSelectedRow(row);
  };

  const onModal = (e: any) => {
    if (!e.target.checked) {
      setSelectedRow(null);
    }
  };

  const onCloseModal = () => {
    if (modalRef.current) {
      modalRef.current.checked = false;
    }
  };

  return (
    <div className="block overflow-hidden">
      <DataTable
        columns={columns}
        data={data?.partnerWorks ?? []}
        customStyles={customStyles}
        highlightOnHover
        noDataComponent={
          <div className="grid place-items-center	">
            <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
            <span className="text-gray-500 text-sm ">Хоосон байна</span>
          </div>
        }
        pagination
        responsive
        progressComponent={
          <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
        }
      />

      <input
        ref={modalRef}
        onChange={onModal}
        className="modal-state"
        id="modal-1"
        type="checkbox"
      />
      <div className="modal">
        <label className="modal-overlay" htmlFor="modal-1"></label>

        <div className="modal-content flex flex-col  w-5/12 p-8 pt-2 ">
          <div className="flex place-items-center place-content-between">
            <label
              htmlFor="modal-1"
              className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
            >
              ✕
            </label>
          </div>
          <div className="pb-4 pt-4 grid  justify-items-center">
            <CiWarning className="text-8xl text-yellow-400" />
            <span className="text-sm"> Та ажил дуусгахдаа итгэлтэй байна уу?</span>
          </div>
          <div className="  flex  w-full gap-4">
            <div className="w-[50%]">
              <Button text="Хаах" isSolid onClick={() => onCloseModal()} />
            </div>
            <div className="w-[50%]">
              <Button
                text="Дуусгах"
                loading={completing}
                onClick={() => onCompleted(selectedRow.id)}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
