import React, { ReactNode, useEffect } from 'react';
import { useCallStore } from 'context/call.store';
import { getPayload } from './auth';
import { IOffer, ITask, ITransaction } from 'types';
import { useQuery, useSubscription } from '@apollo/client';
import { useNotifications } from './notification';
import {
  GET_TRANSACTION,
  GET_TRANSACTIONS,
  GET_EMPLOYEE,
  GET_PARTNER_PLANS,
  GET_TASKS,
  GET_OFFERS,
} from 'graphql/query';
import { ON_PAID_TRANSACTION } from 'graphql/subscription/transaction';
import { ON_UPDATE_TASK } from 'graphql/subscription/task';
import { ON_UPDATED_OFFER } from 'graphql/subscription/offer';

interface SubscriptionProviderProps {
  children: ReactNode;
}

const SubscriptionProvider: React.FC<SubscriptionProviderProps> = ({ children }) => {
  const { setStep, setPartnerId } = useCallStore();
  const { addNotification } = useNotifications();

  const { data: employeeData } = useQuery(GET_EMPLOYEE, {
    skip: !getPayload()?.sub,
    onCompleted(data) {
      setPartnerId(data.getEmployee.partnerId);
    },
  });
  const { data: transactionsData, loading: transactionsLoading } = useQuery(GET_TRANSACTIONS, {
    skip: !employeeData?.getEmployee.partnerId,
  });

  const {
    data: tasksData,
    loading: taskLoading,
    refetch: taskRefetch,
  } = useQuery(GET_TASKS, {
    skip: !employeeData?.getEmployee.partnerId,
  });

  const {
    data: partnerPlans,
    loading: planLoading,
    refetch,
  } = useQuery(GET_PARTNER_PLANS, {
    skip: !employeeData?.getEmployee.partnerId,
    variables: { id: employeeData?.getEmployee.partnerId },
  });

  useSubscription(ON_PAID_TRANSACTION, {
    variables: { user: employeeData?.getEmployee.partnerId },
    skip: !employeeData?.getEmployee.partnerId,
    onData: ({ client, data }) => {
      if (!data) return;

      const { event, transaction: subscriptionTransaction } = data.data.onPaidTransaction;
      setStep(5);
      addNotification('Төлбөр амжилттай төлөгдлөө.', 'success');
      if (transactionsLoading) return;

      const existingTransactions =
        client.readQuery<{ getTransactions: ITransaction[] }>({
          query: GET_TRANSACTIONS,
        })?.getTransactions || [];

      const updatedTransactions = existingTransactions.map((transaction) =>
        transaction.id === subscriptionTransaction.id ? subscriptionTransaction : transaction,
      );

      if (
        event === 'UPDATED' &&
        !updatedTransactions.find((t) => t.id === subscriptionTransaction.id)
      ) {
        updatedTransactions.push(subscriptionTransaction);
      }

      refetch();

      client.writeQuery({
        query: GET_TRANSACTIONS,
        data: { getTransactions: updatedTransactions },
      });

      client.writeQuery({
        query: GET_TRANSACTION,
        variables: { id: subscriptionTransaction.id },
        data: { getTransaction: subscriptionTransaction },
      });
    },
    onError: (error) => {
      console.error('Subscription error:', error);
      addNotification('Төлбөрийн мэдээлэл шинэчлэхэд алдаа гарлаа.', 'error');
    },
  });

  useSubscription(ON_UPDATE_TASK, {
    variables: {},
    skip: !employeeData?.getEmployee.partnerId,
    onData: ({ client, data }) => {
      if (!data) return;

      const caches = client.readQuery<{ getTasks: ITask[] }>({ query: GET_TASKS });
      if (!caches?.getTasks) return;
      const { event, task: subscriptionTask } = data.data.onUpdateTask;
      let updateTasks = caches.getTasks.map((task) =>
        task.id === subscriptionTask.id ? subscriptionTask : task,
      );
      switch (event) {
        case 'CREATED':
        case 'UPDATED':
          updateTasks = updateTasks.filter((task) => task.id !== subscriptionTask.id);
          updateTasks.unshift(subscriptionTask);
          break;
        case 'DELETE':
          updateTasks = updateTasks.filter((task) => task.id !== subscriptionTask.id);
          break;
        default:
          return;
      }

      client.writeQuery({
        query: GET_TASKS,
        data: { getTasks: updateTasks },
      });
    },
  });

  useSubscription(ON_UPDATED_OFFER, {
    variables: { partner: employeeData?.getEmployee.partnerId },
    skip: !employeeData?.getEmployee.partnerId,
    onData: ({ client, data }) => {
      if (!data) return;
      const caches = client.readQuery<{ getOffers: IOffer[] }>({ query: GET_OFFERS });
      if (!caches?.getOffers) return;
      const { event, offer: subscriptionOffer } = data.data.onUpdatedOffer;
      let updateOffers = caches.getOffers.map((offer) =>
        offer.id === subscriptionOffer.id ? subscriptionOffer : offer,
      );
      switch (event) {
        case 'CREATED':
        case 'UPDATED':
          updateOffers = updateOffers.filter((offer) => offer.id !== subscriptionOffer.id);
          updateOffers.unshift(subscriptionOffer);
          break;
        case 'DELETE':
          updateOffers = updateOffers.filter((offer) => offer.id !== subscriptionOffer.id);
          break;
        default:
          return;
      }

      taskRefetch();

      client.writeQuery({
        query: GET_OFFERS,
        data: { getOffers: updateOffers },
      });
    },
  });

  useEffect(() => {
    if (transactionsLoading) return;
  }, [transactionsLoading, transactionsData]);

  useEffect(() => {
    if (taskLoading) return;
  }, [taskLoading, tasksData]);

  useEffect(() => {
    if (planLoading) return;
  }, [planLoading, partnerPlans]);

  return <>{children}</>;
};

export default SubscriptionProvider;
