import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import AttachFile from 'components/AttachFile';

interface IndexProps {
  control: Control<any, any>;
  name: string;
  buttonText?: string;
}

const Index: React.FC<IndexProps> = ({ control, name, buttonText }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value }, formState: { errors } }) => (
        <>
          <AttachFile files={value} onChange={onChange} buttonText={buttonText} />
          <p className="mt-2 mb-2 text-red-500 text-xs">
            {errors && (errors[name] as FieldError)?.message}
          </p>
        </>
      )}
    />
  );
};

export default Index;
