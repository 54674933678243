import { gql } from '@apollo/client';

export const REGISTER_PARTNER = gql`
  mutation registerPartner($input: registerPartnerInput!) {
    registerPartner(input: $input) {
      token
    }
  }
`;

export const LOGIN_BY_EMPLOYEE = gql`
  mutation loginByEmployee($email: String!, $password: String!) {
    loginByEmployee(email: $email, password: $password) {
      token
    }
  }
`;

export const FORGOT_EMPLOY_PASSWORD = gql`
  mutation forgotEmployPassword($token: String!, $password: String!) {
    forgotEmployPassword(token: $token, password: $password) {
      token
    }
  }
`;

export const PAY_PLAN = gql`
  mutation payPlan($id: ID!, $input: LicenseInput!) {
    payPlan(id: $id, input: $input) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      object
      upload
      links {
        name
        logo
        description
        link
      }
    }
  }
`;
