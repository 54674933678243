import { planPermittedSchema } from 'components/resolvers';
import { FieldValues, useForm } from 'react-hook-form';
import FormInputTemplate from 'templates/FormInputTemplate';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate, useParams } from 'react-router-dom';
import ControlledAttachFile from 'components/ControlledForms/ControlledAttachFile';
import { useMutation, useQuery } from '@apollo/client';
import { GET_PLAN } from 'graphql/query';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import DashboardForm from 'templates/DashboardForm';
import ControlledSelect from 'components/ControlledForms/ControlledSelect';
import { isEmpty } from 'lodash';
import { formatWithCommas, permitAttachmentText, specialPermits, uploadToS3 } from 'utils';
import ControlledItem from 'components/ControlledForms/ControlledItem';
import ControlledBank from 'components/ControlledForms/ControlledBank';
import { certificateTypes, experiences } from 'mocks';
import { PAY_PLAN, VALIDATE_TRANSACTION } from 'graphql/mutation';
import { CiWarning } from 'react-icons/ci';
import { useState } from 'react';
import { ConvertImgUrl } from 'tools';
import { useNotifications } from 'providers/notification';
import { LuCheckCircle } from 'react-icons/lu';
import Button from 'components/Button';
import { useCallStore } from 'context';

const Permit = () => {
  let history = useNavigate();
  const { step, setStep } = useCallStore();
  const { addNotification } = useNotifications();
  const { id } = useParams<{ id: string }>();
  const [transaction, setTransaction] = useState<any>();
  const [attaching, setAttaching] = useState(false);

  const { data: plan, loading } = useQuery(GET_PLAN, {
    skip: !id,
    variables: { id },
    onCompleted: (data) => {
      if (step < 3) {
        setStep(2);
      }
    },
  });

  const { control, handleSubmit, watch } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(planPermittedSchema(plan?.getPlan.type, step)) as any,
    defaultValues: {
      files: [],
      files001: [],
      files002: [],
    },
  });

  const { certificateType, specialPermit, files, files001, files002, paymentType, experience } =
    watch();

  const uploadS3 = async (files: any[], uploadUrl: string) => {
    for (const file of files) {
      if (uploadUrl) {
        try {
          await uploadToS3(uploadUrl, file.content, file);
        } catch (uploadError) {
          addNotification(`Failed to upload ${file.name}`, 'error');
          console.error(`Error uploading file ${file.name}:`, uploadError);
        }
      } else {
        addNotification(`Upload URL not found for ${file.name}`, 'error');
      }
    }
  };

  const [payPlan, { loading: paying }] = useMutation(PAY_PLAN, {
    onCompleted: async (data) => {
      let link = null;
      if (data.payPlan.links) {
        link = data.payPlan.links.find(
          (link: any) => link.name.toUpperCase() === paymentType.toUpperCase(),
        )?.link;
      }

      if (link) {
        window.location.href = link;
      }

      if (plan.getPlan.type !== 'technicalControl') {
        await uploadS3(files, data.payPlan.upload);
      } else if (certificateType === '001') {
        await uploadS3(files001, data.payPlan.upload);
      } else if (certificateType === '002') {
        await uploadS3(files002, data.payPlan.upload);
      }

      setTransaction(data.payPlan);
      setAttaching(false);
      setStep(4);
    },
  });

  const [validateTransaction, { loading: validating }] = useMutation(VALIDATE_TRANSACTION, {
    onCompleted: async (data) => {
      if (data.validateTransaction) {
        setStep(5);
      } else {
        addNotification('Төлбөр төлөгдсөнгүй', 'error');
      }
    },
    onError: (error) => {
      addNotification('Амжилтгүй', 'error');
    },
  });

  if (loading)
    return (
      <div className="flex w-full h-full items-center justify-center">
        <AiOutlineLoading3Quarters className="animate-spin h-10 w-10 mr-2 " />
      </div>
    );

  const onSubmit = (data: FieldValues) => {
    if (step === 2) {
      setStep(3);
    } else if (step === 3) {
      let targetFile: any;

      if (plan.getPlan.type !== 'technicalControl') {
        targetFile = files[0];
      } else if (certificateType === '001') {
        targetFile = files001[0];
      } else if (certificateType === '002') {
        targetFile = files002[0];
      }

      if (isEmpty(targetFile)) {
        setStep(2);
      }

      let input = {
        type: plan.getPlan.type,
        experience: experience,
        specialPermit: plan.getPlan.type !== 'technicalControl' ? specialPermit : certificateType,
        upload: targetFile.name,
      };
      setAttaching(true);
      payPlan({ variables: { id, input } });
    } else if (step === 4) {
      setStep(5);
    } else if (step === 5) {
      history('/');
    }
  };

  const goBackStep = () => {
    if (step === 2) {
      history('/plan');
    }
    if (step === 3) {
      setStep(2);
    }
  };

  const validate = () => {
    validateTransaction({ variables: { id: transaction.id } });
  };

  const renderFooter = (step: number) => {
    switch (step) {
      case 4:
        return (
          <div className="flex place-content-center w-full mt-4 border-t md:border-0 border-gray-300 p-4 md:p-0  ">
            <div className="w-full sm:w-40 text-center ">
              {transaction?.state === 'PAID' ? (
                <Button loading={loading} text="Үргэлжлүүлэх" onClick={handleSubmit(onSubmit)} />
              ) : (
                <Button loading={validating} text="Төлбөр шалгах" onClick={validate} />
              )}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <DashboardForm
      onBackHide={step === 1}
      totalSteps={5}
      handleSubmit={handleSubmit}
      activeStep={step}
      onSubmit={onSubmit}
      loading={paying || attaching || loading}
      disabled={paying || attaching || loading}
      renderFooter={renderFooter(step)}
      onBack={() => goBackStep()}
    >
      <FormInputTemplate
        isHide={step === 2}
        headerText="Тусгай зөвшөөрөл"
        headerDescription="Та багц идэвхжүүлэхийн тулд тусгай зөвшөөрөл хавсаргах хэрэгтэй"
      >
        <div className="flex px-6 flex-row max-w-[36rem]	 mx-auto items-center justify-between md:px-6 lg:px-8">
          <div className="w-full flex  justify-center h-full mt-10 ">
            <div className=" grid w-full">
              <ControlledSelect
                items={experiences}
                control={control}
                text="Ажлын туршлага"
                name="experience"
              />
              {plan && plan.getPlan.type !== 'technicalControl' && (
                <ControlledSelect
                  items={specialPermits(plan.getPlan.type)}
                  control={control}
                  text="Тусгай зөвшөөрөлын төрөл"
                  name="specialPermit"
                />
              )}

              {plan && plan.getPlan.type === 'technicalControl' && (
                <ControlledItem data={certificateTypes} control={control} name="certificateType" />
              )}

              {plan && plan.getPlan.type !== 'technicalControl' && (
                <ControlledAttachFile
                  name="files"
                  control={control}
                  buttonText={permitAttachmentText(plan && plan.getPlan.type, certificateType)}
                />
              )}

              {plan && plan.getPlan.type === 'technicalControl' && !isEmpty(certificateType) && (
                <>
                  {certificateType === '001' && (
                    <ControlledAttachFile
                      name="files001"
                      control={control}
                      buttonText={permitAttachmentText(plan && plan.getPlan.type, certificateType)}
                    />
                  )}

                  {certificateType === '002' && (
                    <ControlledAttachFile
                      name="files002"
                      control={control}
                      buttonText={permitAttachmentText(plan && plan.getPlan.type, certificateType)}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 3} headerText="Банк сонгох">
        <div className="flex px-6 flex-row max-w-[36rem]	 mx-auto items-center justify-between md:px-6 lg:px-8">
          <div className="w-full flex  justify-center h-full mt-10 ">
            <div className=" grid w-full">
              <div className="w-full p-2 rounded-lg text-center bg-white border border-gray-500">
                <span className="text-lg font-medium text-gray-800">
                  ₮{formatWithCommas(plan?.getPlan.prices[0].price)}
                </span>
              </div>
              <ControlledBank control={control} name="paymentType" />
            </div>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 4}>
        <div className=" flex w-full  h-full  items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <CiWarning className=" flex lg:hidden animate-pulse text-amber-400 h-20 w-20" />
            {transaction && (
              <img src={ConvertImgUrl(transaction)} alt="pay" className="hidden lg:flex" />
            )}
            <p className="font-medium mt-10 text-gray-700">Таны төлбөр төлөгдсөнөөр баталгаажна.</p>
          </div>
        </div>
      </FormInputTemplate>
      <FormInputTemplate isHide={step === 5}>
        <div className=" flex w-full min-h-40 mt-24 items-center text-center place-content-center">
          <div className="grid place-items-center ">
            <LuCheckCircle className=" animate-pulse text-green-500 h-20 w-20" />
            <p className="font-medium mt-10 text-gray-500">Таны багц амжилттай идэвхжилээ</p>
          </div>
        </div>
      </FormInputTemplate>
    </DashboardForm>
  );
};

export default Permit;
