import { create } from 'zustand';
import { persist, StateStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';

// Define the state storage
const storage: StateStorage = {
  getItem: async (name: string): Promise<string | null> => {
    return (await get(name)) || null;
  },
  setItem: async (name: string, value: string): Promise<void> => {
    await set(name, value);
  },
  removeItem: async (name: string): Promise<void> => {
    await del(name);
  },
};

// Define the state interface
interface ICallStore {
  authenticated: boolean;
  step: number;
  partnerId: string;
  setAuthenticated: (authenticated: boolean) => void;
  setPartnerId: (partnerId: string) => void;
  setStep: (step: number) => void;
}

// Create the Zustand store
export const useCallStore = create<ICallStore>(
  persist(
    (set, get) => ({
      step: 1,
      authenticated: null,
      partnerId: null,
      setAuthenticated: (authenticated: boolean) => {
        set({ authenticated });
      },
      setPartnerId: (partnerId: string) => {
        set({ partnerId });
      },
      setStep: (step: number) => {
        set({ step });
      },
    }),
    {
      name: 'call-storage',
      getStorage: () => storage,
    },
  ) as any,
);
