export const getPurposeWork = (config: string, target: string) => {
  try {
    const items = JSON.parse(config);
    return items.find((item: string) => item.startsWith(target));
  } catch (error) {
    console.error('Error parsing config:', error);
    return null;
  }
};

export const downloadFile = async (path: string) => {
  try {
    const response = await fetch(path, { mode: 'cors' });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', path.split('/').pop() || 'file'); // Extract filename from path
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Clean up
    alert('Download started');
  } catch (error) {
    console.error('Error downloading file:', error);
    alert('Failed to download file');
  }
};
