type Props = {
  item: any;
  selectedItem: any;
  onSelect: (item: any) => void;
};

const Index = ({ item, selectedItem, onSelect }: Props) => {
  return (
    <div
      className={`w-full flex gap-2 p-4 font-semibold text-sm text-start rounded-lg  cursor-pointer  border ${
        selectedItem === item.value
          ? '   border-green-500 bg-green-100  text-green-700 hover:bg-green-200'
          : '  border-gray-400 hover:bg-gray-50'
      }  mb-2 text-sm  `}
      onClick={() => onSelect(item.value)}
    >
      <div className="checkbox-wrapper-18">
        <div className="round">
          <input checked={selectedItem === item.value} type="checkbox" id="checkbox-18" />
          <label htmlFor={'checkbox-18'}></label>
        </div>
      </div>
      <span>{item.name}</span>
    </div>
  );
};

export default Index;
