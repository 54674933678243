import React from 'react';
import SuspenseLoad from '../components/SuspenseLoad';
import RoutesWrapper from 'components/RoutesWrapper';

const Index = () => {
  return (
    <SuspenseLoad>
      <RoutesWrapper />
    </SuspenseLoad>
  );
};
export default Index;
