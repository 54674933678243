type Props = {
  children: React.ReactNode;
};

const Index = ({ children }: Props) => {
  return (
    <div className="w-full p-4  md:p-8  border mt-4 border-gray-300 rounded-xl ">{children}</div>
  );
};

export default Index;
