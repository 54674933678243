import {
  PAGE_AUTH_PAYMENT,
  PAGE_FORGOT_PASSWORD,
  PAGE_REGISTER,
  PAGE_USER_FLOW,
} from 'constants/pages';
import Register from './register';
import UserFlow from './user-flow';
import Auth from './auth';
import AuthPayment from './auth-payment';
import { Route, Routes } from 'react-router-dom';
import ForgotPassword from './forgot-password';

const publicRoutes = () => {
  const routes = [
    {
      path: '/',
      component: <Auth />,
    },
    {
      path: PAGE_REGISTER,
      component: <Register />,
    },
    {
      path: PAGE_USER_FLOW,
      component: <UserFlow />,
    },
    {
      path: PAGE_AUTH_PAYMENT,
      component: <AuthPayment />,
    },
    {
      path: PAGE_FORGOT_PASSWORD,
      component: <ForgotPassword />,
    },
    {
      path: '*',
      component: <Auth />,
    },
  ];

  return (
    <Routes>
      {routes.map((option) => (
        <Route key={option.path} path={option.path} element={option.component} />
      ))}
    </Routes>
  );
};

export default publicRoutes;
