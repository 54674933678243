import { electricalInstallationPermit, workingDrawPermit } from 'mocks';

export function formatWithCommas(number: number): string {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function specialPermits(type: string): any[] {
  switch (type) {
    case 'electricalInstallation':
      return electricalInstallationPermit;
    case 'technicalControl':
      return [];
    case 'workingDraw':
      return workingDrawPermit;
    default:
      return [];
  }
}

export function permitAttachmentText(type: string, certificateType?: string): string {
  switch (type) {
    case 'electricalInstallation':
      return 'Цахилгаан угсралтын тусгай зөвшөөрөл хавсаргах';
    case 'technicalControl':
      return `${
        certificateType === '001'
          ? 'Зөвлөх инженерийн гэрчилгээ хавсаргах'
          : 'Мэргэшсэн инженерийн гэрчилгээ хавсаргах'
      }`;
    case 'workingDraw':
      return 'Зураг төслийн тусгай зөвшөөрөл хавсаргах';
    default:
      return '';
  }
}

export function extractBase64Content(base64Data: string) {
  const matches = base64Data.match(/^data:(.*?);base64,(.*)$/);

  if (!matches || matches.length !== 3) {
    throw new Error('Invalid base64 string');
  }

  const contentType = matches[1];
  const base64Content = matches[2];

  return { contentType, base64Content };
}

export async function uploadToS3(presignedUrl: string, base64Data: any, fileData: any) {
  try {
    const { contentType, base64Content } = extractBase64Content(base64Data);

    const fileBuffer = Buffer.from(base64Content, 'base64');

    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': contentType,
      },
      body: fileBuffer,
    };
    const response = await fetch(presignedUrl, requestOptions);
    if (response.ok) {
      console.log('File uploaded successfully');
    } else {
      console.error('File upload failed:', response.statusText);
    }
  } catch (error) {
    console.error('Error uploading file:', error);
  }
}
