import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';

type Props = {
  control: Control<any, any>;
  items: any[];
  name: string;
  placeholder?: string;
  text?: string;
};

const Index = ({ control, name, text, items, placeholder }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <>
            <div className="space-y-2 w-full">
              {text && <p className="text-sm font-semibold text-gray-800 "> {text}</p>}
              <select
                className="select  select-block text-xs w-full bg-white placeholder:text-sm px-4 py-2 text-gray-800 text-gray1 transition duration-300 border border-gray-300 rounded-lg focus:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-300"
                defaultValue={value}
                ref={ref}
                value={value || ''}
                onChange={(e) => onChange(e.target.value)}
              >
                <option value="" disabled selected hidden>
                  {placeholder || 'Сонгох'}
                </option>
                {items.map((item: any) => (
                  <option
                    key={item.value}
                    value={item.value}
                    title={item.name}
                    className="text-gray-800 text-xs"
                    style={{
                      whiteSpace: 'pre-wrap',
                      overflowWrap: 'break-word',
                    }}
                  >
                    {item.name}
                  </option>
                ))}
              </select>
              <p className="mt-2 mb-2 text-red-500 text-xs">
                {errors && (errors[name] as FieldError)?.message}
              </p>
            </div>
          </>
        );
      }}
    />
  );
};

export default Index;
