import React from 'react';
import Button from 'components/Button';
import Step from 'components/Step';
import { IoArrowBack } from 'react-icons/io5';

type Props = {
  children: React.ReactNode;
  handleSubmit: any;
  onSubmit: any;
  loading?: boolean;
  disabled?: boolean;
  totalSteps?: number;
  activeStep?: number;
  onBack?: () => void;
  onBackHide?: boolean;
  renderFooter?: React.ReactNode;
};

const Index = ({
  children,
  totalSteps,
  handleSubmit,
  onSubmit,
  activeStep,
  disabled,
  onBack,
  loading,
  onBackHide,
  renderFooter,
}: Props) => {
  return (
    <>
      <div className="w-full  md:bg-opacity-90 transition duration-300 ease-in-out">
        <div className=" px-6 flex-row	 mt-4 mx-auto items-center justify-between md:px-6 lg:px-8">
          <div className="w-full mb-4">
            <div className="flex  flex-col max-w-[90rem] bg-white 	  md:items-center md:justify-between md:flex-row ">
              {totalSteps && activeStep && <Step totalSteps={totalSteps} activeStep={activeStep} />}
            </div>
            <div className="flex px-6 flex-row max-w-[90rem]	 pt-4 mx-auto items-center justify-between md:px-6 lg:px-8">
              <div className="hover:bg-gray-100  p-2  rounded-lg">
                {!onBackHide && (
                  <IoArrowBack
                    className=" cursor-pointer h-6 w-6 text-gray-600 "
                    onClick={onBack}
                  />
                )}
              </div>
            </div>
          </div>

          <div className="  w-full">
            {children}
            {renderFooter ? (
              <>
                <div className="text-center w-full ">
                  <div className=" hidden sm:flex  ">{renderFooter}</div>
                </div>
              </>
            ) : (
              <div className="flex w-full place-content-start mt-4">
                <div className="w-20 sm:w-40 text-center ">
                  <div className=" hidden sm:flex ">
                    <Button
                      loading={loading}
                      text="Үргэлжлүүлэх"
                      disabled={disabled}
                      onClick={handleSubmit(onSubmit)}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="fixed sm:hidden bottom-0 w-full z-30 clearNav bg-white md:bg-opacity-90 transition duration-300 ease-in-out">
        <div className="flex flex-col max-w-6xl  mx-auto md:items-center md:justify-between md:flex-row md:px-6 lg:px-8">
          {renderFooter ? (
            <>{renderFooter}</>
          ) : (
            <div className="w-full border-t border-gray-300 p-4 ">
              <Button
                loading={loading}
                text="Үргэлжлүүлэх"
                onClick={handleSubmit(onSubmit)}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Index;
