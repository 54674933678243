import React from 'react';
import { Controller, Control, FieldError } from 'react-hook-form';
import CurrencyInput from 'react-currency-input-field';

type Props = {
  control: Control<any, any>;
  name: string;
  type: string;
  placeholder?: string;
  isFormatted?: boolean;
  text?: string;
};

const Index: React.FC<Props> = ({ control, name, text, placeholder = '', type }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        const error = errors[name] as FieldError;

        return (
          <div className="space-y-2 w-full">
            {text && <p className="text-sm font-semibold text-gray-800">{text}</p>}
            <CurrencyInput
              className="w-full bg-white dark:bg-white  placeholder:text-sm px-4 py-2 text-gray-800 dark:text-gray-800 transition duration-300 border border-gray-300 dark:border-gray-300 rounded-lg focus:border-transparent focus:outline-none focus:ring-1 focus:ring-gray-300"
              placeholder={placeholder}
              decimalsLimit={2}
              onValueChange={(value, _name, _values) => onChange(value)}
              type={type}
            />
            {error && <p className="mt-2 mb-2 text-red-500 text-xs">{error.message}</p>}
          </div>
        );
      }}
    />
  );
};

export default Index;
