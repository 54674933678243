import { gql } from '@apollo/client';
export const GET_TASKS = gql`
  {
    getTasks {
      id
      type
      state
      isRead
      completedAt
      detailedPicture
      orderId
      taskConfig
      workTypes
      documents {
        id
        name
        path
        input
      }
      offers {
        id
        createdAt
        price
        state
        partnerId
        types
        chargeAmount
      }
    }
  }
`;
