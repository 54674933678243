import { gql } from '@apollo/client';

export const GET_EMPLOYEE = gql`
  {
    getEmployee {
      id
      email
      firstName
      lastName
      partnerId
      role {
        id
        type
        name
        scopes
        state
      }
    }
  }
`;
