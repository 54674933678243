import { gql } from '@apollo/client';
export const GET_OFFERS = gql`
  {
    getOffers {
      id
      createdAt
      price
      state
      partnerId
      types
      chargeAmount
    }
  }
`;

export const GET_OFFER = gql`
  query getOffer($id: ID!) {
    getOffer(id: $id) {
      id
      createdAt
      price
      state
      partnerId
      types
      chargeAmount
    }
  }
`;

export const PARTNER_WORKS = gql`
  {
    partnerWorks {
      id
      createdAt
      price
      state
      partnerId
      types
      chargeAmount
      task {
        id
        type
        state
        isRead
        completedAt
        detailedPicture
        orderId
        taskConfig
        workTypes
        documents {
          id
          name
          path
          input
        }
        order {
          id
          user {
            id
            phone
            firstName
          }
        }
      }
    }
  }
`;
