type Props = {
  children: React.ReactNode;
  headerText?: string;
  formTypeName?: string;
  headerDescription?: string;
  isHide?: boolean;
};

const Index = ({ children, headerText, formTypeName, headerDescription, isHide }: Props) => {
  return (
    <>
      {isHide && (
        <div className="flex w-full min-h-[26rem]	">
          <form className="w-full h-full">
            <div className="w-full grid ">
              <span className="text-sm text-gray-500  mb-3">{formTypeName}</span>
              <span className="text-4xl font-semibold text-gray-800  mb-3">{headerText}</span>
              <span className="text-sm text-gray-500 mb-3 ">{headerDescription}</span>
            </div>
            <main className="mb-32 md:mb-0">{children}</main>
          </form>
        </div>
      )}
    </>
  );
};

export default Index;
