import { useQuery } from '@apollo/client';
import { GET_OFFERS } from 'graphql/query';
import moment from 'moment';
import { useRef, useState } from 'react';
import DataTable, { TableStyles } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { AiOutlineInbox } from 'react-icons/ai';
import { orderStatusColor } from 'tools';
import { formatWithCommas } from 'utils';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import Button from 'components/Button';
import { useNavigate } from 'react-router-dom';

const Work = () => {
  let history = useNavigate();
  const modalRef = useRef<any>(null);
  const { data, loading } = useQuery(GET_OFFERS);
  const { t } = useTranslation('language');
  const [selectedRow, setSelectedRow] = useState<any>();

  const customStyles: TableStyles = {
    header: {
      style: {
        minHeight: '56px',
      },
    },
    cells: {
      style: {
        '&:not(:last-of-type)': {
          height: '60px',
        },
      },
    },
  };

  if (loading)
    return (
      <div className="flex w-full h-full items-center justify-center">
        <AiOutlineLoading3Quarters className="animate-spin h-10 w-10 mr-2 " />
      </div>
    );

  const columns: any = [
    {
      name: 'Илгээсэн огноо',
      selector: (row: any) => (
        <span className="font-normal">{moment(row.createdAt).format('YYYY-MM-DD')}</span>
      ),
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Төрөл',
      selector: (row: any) => (
        <span className="font-normal">
          {row.types.map((type: string, index: number) => (
            <div key={index}>{t(type)}</div>
          ))}
        </span>
      ),
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Үнийн дүн',
      selector: (row: any) => <span className="font-normal">{formatWithCommas(row.price)} ₮</span>,
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Шимтгэл',
      selector: (row: any) => (
        <span className="font-normal">{formatWithCommas(row.chargeAmount)} ₮</span>
      ),
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
    {
      name: 'Төлөв',
      selector: (row: any) => (
        <span
          className={`badge text-wrap cursor-pointer font-normal		 ${orderStatusColor(
            row.state,
          )} place-content-center`}
        >
          {t(`offer.${row.state}`)}
        </span>
      ),
      maxWidth: '600px',
      wrap: true,
      sortable: true,
      center: true,
    },
  ];

  const onSelect = (row: any) => {
    setSelectedRow(row);
  };

  const goCharge = () => {
    if (selectedRow) {
      history(`/offer/charge/${selectedRow?.id}`);
    }
  };

  const offers = data?.getOffers;

  return (
    <div className="block overflow-hidden ">
      <DataTable
        columns={columns}
        data={offers ?? []}
        customStyles={customStyles}
        highlightOnHover
        noDataComponent={
          <div className="grid place-items-center	">
            <AiOutlineInbox className="animate-pulse h-12 w-12 text-gray-500 " />
            <span className="text-gray-500 text-sm ">Хоосон байна</span>
          </div>
        }
        responsive
        pagination
        progressComponent={
          <AiOutlineLoading3Quarters className="animate-spin  h-12 w-12 text-gray-500  " />
        }
      />
      {selectedRow && (
        <>
          <input ref={modalRef} className="modal-state" id="modal-1" type="checkbox" />
          <div className="modal">
            <label className="modal-overlay" htmlFor="modal-1"></label>
            <div className="modal-content flex flex-col w-7/12  sm:w-5/12 p-8 pt-2 ">
              <label
                htmlFor="modal-1"
                className="btn btn-sm btn-circle btn-ghost absolute right-1 top-1"
              >
                ✕
              </label>
              <div className="p-4 mb-4 mt-4 text-blue-800 border border-blue-300 rounded-lg bg-blue-50  ">
                <div className="flex items-start">
                  <div className="h-full">
                    <svg
                      className="flex-shrink-0 w-5 h-5 me-2"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                    >
                      <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                    </svg>
                  </div>

                  <span className="sr-only">Info</span>
                  <span className="text-sm font-medium w-full text-center  items-center ">
                    Үйлчилгээний шимтгэл 2%-иар тооцогдоно. <br />
                    <div className="w-full  items-center text-center mt-4">
                      <span className=" font-semibold ">
                        Шимтгэл ₮ {formatWithCommas(selectedRow?.chargeAmount)}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              <div className="  w-full">
                <Button text="Үргэлжлүүлэх" loading={loading} onClick={goCharge} />
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Work;
