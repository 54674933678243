import ListButton from 'components/ListButton';
import { Controller, Control, FieldError } from 'react-hook-form';

type Props = {
  control: Control<any, any>;
  name: string;
  data: any[];
};

const Index = ({ control, name, data }: Props) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref }, formState: { errors } }) => {
        return (
          <div className=" w-full" ref={ref}>
            {data.map((item, index) => (
              <ListButton key={index} item={item} selectedItem={value} onSelect={onChange} />
            ))}
            <p className="mt-2 mb-2 text-red-500 text-xs">
              {errors && (errors[name] as FieldError)?.message}
            </p>
          </div>
        );
      }}
    />
  );
};

export default Index;
