import { gql } from '@apollo/client';

export const CREATE_OFFER = gql`
  mutation createOffer($id: ID!, $price: Float!, $types: [String!]) {
    createOffer(id: $id, price: $price, types: $types) {
      id
      createdAt
      price
      state
      types
      chargeAmount
      partnerId
    }
  }
`;

export const COMPLETE_OFFER = gql`
  mutation completeOffer($id: ID!) {
    completeOffer(id: $id) {
      id
      createdAt
      price
      state
      types
      chargeAmount
      partnerId
    }
  }
`;

export const PAY_CHARGE = gql`
  mutation payCharge($id: ID!) {
    payCharge(id: $id) {
      id
      token
      invoice
      image
      description
      entry
      amount
      state
      purpose
      object
      links {
        name
        logo
        description
        link
      }
    }
  }
`;
