import { useNavigate } from 'react-router-dom';
import { PAGE_FORGOT_PASSWORD, PAGE_INDEX, PAGE_REGISTER } from 'constants/pages';
import ControlledInput from 'components/ControlledForms/ControlledInput';
import { FieldValues, useForm } from 'react-hook-form';
import Button from 'components/Button';
import FlexRowTemplate from 'templates/FlexRowTemplate';
import { LOGIN_BY_EMPLOYEE } from 'graphql/mutation';
import { useMutation } from '@apollo/client';
import { useNotifications } from 'providers/notification';
import { useContext } from 'react';
import { AuthContext } from 'providers/auth';
import { loginSchema } from 'components/resolvers/login';
import { yupResolver } from '@hookform/resolvers/yup';

const Index = () => {
  let history = useNavigate();
  const { addNotification } = useNotifications();
  const { authenticate } = useContext(AuthContext);

  const { control, handleSubmit } = useForm<FieldValues>({
    mode: 'all',
    resolver: yupResolver(loginSchema) as any,
    defaultValues: {
      step: 1,
    },
  });

  const [loginByEmployee, { loading: loging }] = useMutation(LOGIN_BY_EMPLOYEE, {
    onCompleted: (data) => {
      authenticate(data.loginByEmployee.token, () => {
        history(PAGE_INDEX);
        addNotification('Амжилттай нэвтэрлээ!', 'success');
      });
    },
    onError: (error) => {
      addNotification(error.message, 'error');
    },
  });

  const forgotPassword = () => {
    history(PAGE_FORGOT_PASSWORD);
  };

  const onSubmit = (data: FieldValues) => {
    const { email, password } = data;
    loginByEmployee({ variables: { email, password } });
  };

  return (
    <div className="grid  grid-cols-1 md:grid-cols-2 h-screen">
      <div className="">
        <div className="w-full p-8 "></div>
        <FlexRowTemplate>
          <div className=" grid w-full text-center mb-4">
            <span className="text-xl text-[#164789] font-semibold mb-4">
              Ажил гүйцэтгэгчдэд зориулсан холболт
            </span>
            <span className="text-sm text-gray-500 ">
              Бүртгэл үүсгэх эсвэл нэвтэрч, ажлын захиалга бүхий цахим биржэд оролцож ажил гүйцэтгэх
              боломжтой.
            </span>
          </div>
        </FlexRowTemplate>
        <FlexRowTemplate>
          <ControlledInput
            control={control}
            placeholder="И мэйл хаягаа оруулна уу"
            name="email"
            inputMode="email"
            type="text"
          />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <ControlledInput
            control={control}
            placeholder="Нууц оруулна уу"
            name="password"
            type="password"
          />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <div className=" grid w-full text-end mb-1">
            <span className="text-sm text-gray-800 " onClick={forgotPassword}>
              Нууц үгээ мартсан
            </span>
          </div>
        </FlexRowTemplate>
        <FlexRowTemplate>
          <Button loading={loging} text="Үргэлжлүүлэх" onClick={handleSubmit(onSubmit)} />
        </FlexRowTemplate>
        <FlexRowTemplate>
          <div className=" w-full divider divider-horizontal">
            <span className="text-sm text-gray-400">Эсвэл</span>
          </div>
        </FlexRowTemplate>
        <FlexRowTemplate>
          <Button text="Бүртгүүлэх" onClick={() => history(PAGE_REGISTER)} isSolid />
        </FlexRowTemplate>
      </div>

      <div className=" hidden   md:flex ">
        <img src="/images/login.jpg" alt="login" className="bg-contain bg-center h-dvh w-dvw" />
      </div>
    </div>
  );
};
export default Index;
